<template>
  <div class="admin-container" v-if="data != null">
    <v-row class="justify-space-between">
      <v-col cols="12" md="3">
        <h1 class="col-secondary-1">Smlouvy</h1>
        <span class="col-secondary-2"> </span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col align-self="center">
        <v-row>
          <v-col
            align-self="center"
            class="pa-0 flex-1 mt-8 mt-md-0 d-flex justify-end flex-wrap flex-md-nowrap"
          >
            <v-btn
              x-small
              height="38"
              color="primary"
              tile
              class="mr-md-2 mobile-width-send-message mb-4 mb-md-0"
              @click="toMessages"
            >
              <div class="d-flex flex-align-center">
                <img
                  :src="require('@/assets/poslat_zpravu.svg')"
                  class="icon-wrapp mr-2"
                  style="width: 13px; height: 13px"
                />
                Poslat zprávu
              </div>
            </v-btn>

            <v-btn
              x-small
              height="38"
              class="mobile-width-send-message mr-md-2 mb-4 md-mb-0"
              tile
              @click="downloadAll"
              >Stáhnout vše</v-btn
            >
            <v-btn
              v-if="user.role != 'END_USER' && user.role != 'COMPANY_CUSTOMER'"
              x-small
              tile
              color="secondary"
              height="38"
              @click="
                $router.push({
                  name: 'Contracts-new',
                })
              "
              class="mb-4 mt-mb-0 mobile-width-send-message"
              ><v-icon small color="primary" class="mr-2">mdi-plus</v-icon> Nová
              smlouva</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-10">
      <v-col
        cols="12"
        md="4"
        v-for="(contract, contractIdx) in data"
        :key="contractIdx"
      >
        <v-card class="pa-6 d-flex flex-column" style="height: 100%">
          <!-- min-width="365" -->
          <!-- :to="{
            name: 'Contracts-detail',
            params: {
              id: contract.id,
            },
          }" -->
          <v-row
            style="cursor: pointer"
            @click="
              $router.push({
                name: 'Contracts-detail',
                params: { id: contract.id },
              })
            "
          >
            <v-col class="flex-grow-0">
              <div
                class="projecting-directory type-icon"
                style="width: 122px; height: 122px"
                :style="documentIcon"
              >
              </div>
            </v-col>
            <v-col>
              <div class="projecting-card-name">{{ contract.name }}</div>
              <div class="projecting-card-date mt-2">
                {{ contract.date_of_record }}
              </div>
              <div
                class="projecting-card-editor mt-2"
                v-if="contract.project_manager"
              >
                {{ contract.project_manager.first_name }}
                {{ contract.project_manager.last_name }}
              </div>
              <div class="col-secondary-2 mt-4">
                {{ contract.description }}
              </div>
            </v-col>
          </v-row>
          <v-row class="flex-grow-0 mt-6">
            <input
              style="display: none"
              ref="documentFile"
              type="file"
              class="input--file"
              @input="handleInputFile($event.target.files[0])"
            />

            <input
              style="display: none"
              ref="photoFile"
              type="file"
              accept="image/*"
              capture="camera"
              class="input--file"
              @input="handleInputFile($event.target.files[0])"
            />

            <v-col class="text-right">
              <v-btn
                v-if="['PROJECT_MANAGER', 'SUPERUSER'].includes(user.role)"
                @click.stop="
                  handleClick('documentFile', contractIdx, contract.id)
                "
                tile
                small
                color="secondary"
                class="contract-btn"
                style="font-size: 9px"
                >Vložit soubor</v-btn
              >
              <v-btn
                v-if="['PROJECT_MANAGER', 'SUPERUSER'].includes(user.role)"
                @click.stop="handleClick('photoFile', contractIdx, contract.id)"
                tile
                small
                color="secondary"
                class="contract-btn"
                style="font-size: 9px;"
                >Pořídit foto</v-btn
              >
               <!-- z-index: 99999 -->
              <v-btn
                text
                small
                @click.stop="downloadSingle(contract.id)"
                class="contract-btn"
              >
                <img
                  :src="require('@/assets/stahnout.svg')"
                  class="icon-wrapp mr-2"
                  style="width: 15px; height: 15px"
                />
                <span style="font-size: 9px">Stáhnout</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import authHandler from "../api/authHandler";
import permissionDeniedHandler from "../api/permissionDeniedHandler";
import bus from "../plugins/bus";
import documentService from "../api/documentService";
import downloadService from "../api/downloadService";
import errorHandler from "../api/errorHandler";
import contractService from "../api/contractService";
export default {
  data() {
    return {
      data: null,
      focusedId: null,
    };
  },
  async created() {
    try {
      if (this.getSelectedBuilding() != null) {
        await this.index();
      } else {
        this.$router.push({ name: "Dashboard" });
      }
    } catch (error) {
      if (!authHandler(error)) this.$router.push({ name: "Login" });
      permissionDeniedHandler(error, this.$router);
    }
  },
  methods: {
    handleClick(ref, idx, id) {
      console.debug("WTF ?");
      this.focusedId = id;
      this.$refs[ref][idx].click();
    },
    async handleInputFile(f) {
      try {
        bus.$emit("processing");
        const { data: document } = await documentService.store(f);
        console.debug(document);
        await contractService.storeDocuments(this.focusedId, {
          document_id: document.id,
          name: document.client_name,
        });
        bus.$emit("processing", false);
          bus.$emit("snackbar", {
          text: `Podařilo se nahrát dokumenty`,
          color: "info",
        });
      } catch (error) {
        bus.$emit("processing", false);
        errorHandler(error);
      }
      this.focusedId = null;
    },
    async downloadSingle(id) {
      try {
        bus.$emit("processing");
        const { data } = await contractService.downloadSingle(id);
        await downloadService.downloadFromCode(data.code);
        bus.$emit("processing", false);
      } catch (error) {
        bus.$emit("processing", false);
        errorHandler(error);
      }
    },
    async downloadAll() {
      try {
        bus.$emit("processing");
        const { data } = await contractService.download();
        await downloadService.downloadFromCode(data.code);
        bus.$emit("processing", false);
      } catch (error) {
        bus.$emit("processing", false);
        errorHandler(error);
      }
    },
    toMessages() {
      if (["END_USER", "COMPANY_CUSTOMER"].includes(this.user.role)) {
        this.$router.push({
          name: "MessagesDetail",
          params: { id: this.getSelectedBuilding().project_manager.id },
          query: {
            section: "contract",
          },
        });
      }
      if (["PROJECT_MANAGER", "SUPERUSER"].includes(this.user.role)) {
        this.$router.push({
          name: "MessagesDetail",
          params: { id: this.getSelectedBuilding().investor.id },
          query: {
            section: "contract",
          },
        });
      }
    },
    getSelectedBuilding() {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      return building;
    },
    async index() {
      try {
        bus.$emit("processing");
        const data = await contractService.index();
        this.data = data;
        console.debug(data);
        bus.$emit("processing", false);
        await bus.$emit("refresh_sections");
      } catch (error) {
        bus.$emit("processing", false);
        errorHandler(error);
      }
    },
    async store() {
      try {
        bus.$emit("processing");
        await projectingService.store({
          ...this.data,
        });
        bus.$emit("processing", false);
        this.$router.push({ name: "Projecting" });
      } catch (error) {
        bus.$emit("processing", false);
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit("snackbar", {
            text: error.response.data.error.message,
            color: "error",
          });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    documentIcon(){
      return {
        backgroundImage: `url(${require("@/assets/document.svg")})`
      }
    }
  },
};
</script>

<style></style>
